import { Center, Text, VStack } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';

import { StandardLayout } from '~/components/layout';

const HomePage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  const person = data.contentfulPerson;

  return (
    <StandardLayout>
      <Center h="100%">
        <VStack mb="24">
          <Text textStyle="heroTitle" mb="4">
            {person?.name}
          </Text>
          <Text textStyle="heroTagline">{person?.title}</Text>
        </VStack>
      </Center>
    </StandardLayout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query HomePage {
    contentfulPerson(isPortfolioOwner: { eq: true }) {
      name
      title
    }
  }
`;
